import { getI18nInstance } from '@wkda/funnel-components';
import { messages } from '../../translations/wkda-sf/nl-be/messages';
import config from '../config';

/**@type {String} */
const locale = config.locale;

export async function activate() {
}

export function initializeI18n(i18n = getI18nInstance()) {
  i18n.load(locale, messages);
  i18n.activate(locale);
}
